@import '../../styles/customMediaQueries.css';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  /* Disable image dragging */
  -webkit-user-drag: none;
  user-drag: none;
  /* Disable selecting */
  -webkit-user-select: none;
  user-select: none;
}

.image {
  width: 100%;
  height: 100%;
  /* The base ResponsiveImage will be rendered inside this */
}

.protection {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* Transparent overlay to prevent easy right-click saving */
  pointer-events: none;
  cursor: default;
}