.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid var(--colorGrey100, #e7e7e7);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  outline: none;
  font-size: 14px;
}

.root:hover, .root:focus {
  border-color: var(--colorGrey300, #999);
  background-color: var(--colorGrey50, #f9f9f9);
}

.iconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.icon {
  height: 24px;
  width: 24px;
  transition: all 0.2s ease;
}

.iconFilled {
  fill: var(--marketplaceColor, #ff5a5f);
  stroke: none;
}

.iconOutline {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5px;
}

.text {
  margin-left: 8px;
  font-weight: 500;
}

/* Styles for saved state */
.saved {
  color: var(--marketplaceColor, #ff5a5f);
  border-color: var(--marketplaceColor, #ff5a5f);
}

.saved:hover, .saved:focus {
  background-color: var(--marketplaceColorLight, #fff0f0);
  border-color: var(--marketplaceColor, #ff5a5f);
}

/* Styles for not saved state */
.notSaved {
  color: var(--colorGrey700, #4a4a4a);
}