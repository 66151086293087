.root {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.root:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.root:focus {
  outline: none;
}

.svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: var(--marketplaceColor);
  stroke-width: 2px;
  transition: all 0.2s ease-in-out;
}

.filled .svg {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}

.outline .svg {
  fill: none;
  stroke: var(--marketplaceColor);
}

/* Add animation for heart filling */
@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.filled .svg {
  animation: heartBeat 0.5s ease-in-out;
}

/* Disabled state */
.root:disabled {
  cursor: not-allowed;
} 